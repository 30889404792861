import { AuthContextProvider } from "@/contexts/AuthContext";
import "../styles/globals.css";
import createEmotionCache from "@/theme/createEmotionCache";
import { theme } from "@/theme/theme";
import { CacheProvider, EmotionCache } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { ReactNode, ReactElement } from "react";
import { ROUTES } from "@/constants/routes";
import ProtectedRoute from "@/components/routes/ProtectedRoute";
import { useRouter } from "next/router";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SuccessDialogProvider from "@/contexts/SuccessDialogContext";
import ErrorDialogProvider from "@/contexts/ErrorDialogContext";
import { NextIntlClientProvider } from "next-intl";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY);
const clientSideEmotionCache = createEmotionCache();

const queryClient = new QueryClient();

const noAuthRequired = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.FEEDBACK_LOGIN,
  ROUTES.FEEDBACK_HOME,
  "/sentry_sample_error",
  "/_error",
];

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type MyAppProps = AppProps & {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
};

export default function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: MyAppProps) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const renderTitle = (path: string) => {
    if (path.split("/").length >= 2) {
      let page = path.split("/")[1];
      if (page != "") {
        if (page.includes("-")) {
          page = page
            .split("-")
            .map((x) => titleCase(x))
            .join(" ");
        }
        return `Arbor · ${titleCase(page)}`;
      } else {
        return `Arbor`;
      }
    } else {
      return `Arbor`;
    }
  };

  return (
    <NextIntlClientProvider
      locale={router.locale}
      messages={pageProps.messages}
    >
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>{renderTitle(router.pathname)}</title>
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <SuccessDialogProvider>
                <ErrorDialogProvider>
                  {noAuthRequired.includes(router.pathname) ? (
                    getLayout(<Component {...pageProps} />)
                  ) : (
                    <ProtectedRoute>
                      {getLayout(<Component {...pageProps} />)}
                    </ProtectedRoute>
                  )}
                </ErrorDialogProvider>
              </SuccessDialogProvider>
            </AuthContextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </NextIntlClientProvider>
  );
}
