import React, { useState, useContext, createContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

// Context to manage global error state
const ErrorDialogContext = createContext<{
  showError: (message: string) => void;
}>({} as { showError: (message: string) => void });

export const useErrorDialog = () => useContext(ErrorDialogContext);

const ErrorDialogProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const showError = (message: string) => {
    setErrorMessage(message);
    setOpen(true);
  };

  return (
    <ErrorDialogContext.Provider value={{ showError }}>
      {children}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </ErrorDialogContext.Provider>
  );
};

export default ErrorDialogProvider;
