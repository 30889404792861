export enum ROUTES {
  ROOT = "/",
  LOGIN = "/login",
  ANALYTICS = "/analytics",
  INTERNAL_ANALYTICS = "/analytics/internal",
  HIRING_ANALYTICS = "/analytics/hiring",
  PORTFOLIO_ANALYTICS = "/analytics/portfolio",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  INTEGRATIONS = "/integrations",
  BENCHMAKRS = "/benchmarks",
  VIEW_BENCHMARKS = "/benchmarks/view-benchmarks",
  SURVEY = "/survey",
  SURVEY_EDITOR = "editor",
  SURVEY_PORTFOLIO_EDITOR = "portfolioeditor",
  SURVEY_RESPONDER = "responder",
  SURVEY_LIST = "/survey/list",
  SURVEY_SENT_LIST = "/survey/sent-list",
  SURVEY_SENT_DETAILS = "/survey/sent-details",
  FEEDBACK_HOME = "/feedback/[survey_instance_id]/[responder_id]",
  FEEDBACK_LOGIN = "/feedback/login",
  EMPLOYEE_OVERVIEW = "/collect/employee-overview",
}
