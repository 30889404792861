const typography = {
  h1: {
    fontSize: 96,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "-1.5px",
    lineHeight: "116.70000553131104%",
  },
  h2: {
    fontSize: 60,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "-0.5px",
    lineHeight: "120.00000476837158%",
  },
  h3: {
    fontSize: 48,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0px",
    lineHeight: "116.70000553131104%",
  },
  h4: {
    fontSize: 34,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: "123.50000143051147%",
  },
  h5: {
    fontSize: 24,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0px",
    lineHeight: "133.39999914169312%",
  },
  h6: {
    fontSize: 20,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "160.0000023841858%",
  },
  body1: {
    fontSize: 16,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  body2: {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "142.99999475479126%",
  },
  subtitle1: {
    fontSize: 16,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "175%",
  },
  subtitle2: {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.10000000149011612px",
    lineHeight: "157.00000524520874%",
  },
  overline: {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "1px",
    lineHeight: "266.00000858306885%",
  },
  caption: {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "components/alert-title": {
    fontSize: 16,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "components/alert-description": {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "142.99999475479126%",
  },
  "components/avatar-initials": {
    fontSize: 20,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.14000000059604645px",
    lineHeight: "20px",
  },
  "components/badge-label": {
    fontSize: 12,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.14000000059604645px",
    lineHeight: "20px",
  },
  "components/button-large": {
    fontSize: 15,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.46000000834465027px",
    lineHeight: "26px",
  },
  "components/button-medium": {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "24px",
  },
  "components/button-small": {
    fontSize: 13,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.46000000834465027px",
    lineHeight: "22px",
  },
  "components/input-label": {
    fontSize: 12,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "12px",
  },
  "components/helper-text": {
    fontSize: 12,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "components/input-text": {
    fontSize: 16,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "24px",
  },
  "components/chip-label": {
    fontSize: 13,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.1599999964237213px",
    lineHeight: "18px",
  },
  "components/tooltip-label": {
    fontSize: 10,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0px",
    lineHeight: "14px",
  },
  "components/table-header": {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "24px",
  },
  "components/menu-item": {
    fontSize: 16,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "components/menu-item-dense": {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "24px",
  },
  "components/list-subheader": {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.10000000149011612px",
    lineHeight: "48px",
  },
  "components/bottom-navigation-active-label": {
    fontSize: 14,
    fontFamily: "Satoshi-Variable, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
};

export default typography;
