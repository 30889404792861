const palette = {
  mode: "light" as const,
  divider: "rgba(0,0,0,0.12)",
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.6)",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
  primary: {
    main: "rgba(0, 82, 46, 1)",
    dark: "rgba(0, 57, 30, 1)",
    light: "rgba(33, 135, 83, 1)",
    contrast: "rgba(255, 255, 255, 1)",
    states: {
      outlinedborder: "rgba(0, 82, 46, 0.5)",
    },
  },
  secondary: {
    main: "rgba(216, 120, 48, 1)",
    dark: "rgba(151, 72, 0, 1)",
    light: "rgba(255, 182, 136, 1)",
    contrast: "rgba(255, 255, 255, 1)",
    states: {
      outlinedborder: "rgba(216, 120, 48, 0.5)",
    },
  },
  action: {
    hover: "rgba(0, 0, 0, 0.04)",
    selected: "rgba(0, 0, 0, 0.08)",
    disabledbackground: "rgba(0, 0, 0, 0.12)",
    focus: "rgba(0, 0, 0, 0.12)",
    disabled: "rgba(0, 0, 0, 0.38)",
    active: "rgba(0, 0, 0, 0.56)",
  },
  error: {
    main: "rgba(211, 47, 47, 1)",
    dark: "rgba(198, 40, 40, 1)",
    light: "rgba(239, 83, 80, 1)",
    contrast: "rgba(255, 255, 255, 1)",
    states: {
      outlinedborder: "rgba(211, 47, 47, 0.5)",
    },
  },
  warning: {
    main: "rgba(237, 108, 2, 1)",
    dark: "rgba(230, 81, 0, 1)",
    light: "rgba(255, 152, 0, 1)",
    contrast: "rgba(255, 255, 255, 1)",
    states: {
      outlinedborder: "rgba(237, 108, 2, 0.5)",
    },
  },
  info: {
    main: "rgba(2, 136, 209, 1)",
    dark: "rgba(1, 87, 155, 1)",
    light: "rgba(3, 169, 244, 1)",
    contrast: "rgba(255, 255, 255, 1)",
    states: {
      outlinedborder: "rgba(2, 136, 209, 0.5)",
    },
  },
  success: {
    main: "rgba(46, 125, 50, 1)",
    dark: "rgba(27, 94, 32, 1)",
    light: "rgba(76, 175, 80, 1)",
    contrast: "rgba(255, 255, 255, 1)",
    states: {
      outlinedborder: "rgba(46, 125, 50, 0.5)",
    },
  },
  background: {
    default: "rgba(255, 255, 255, 1)",
    "paper-elevation-0": "rgba(255, 255, 255, 1)",
    "paper-elevation-1": "rgba(255, 255, 255, 1)",
    "paper-elevation-2": "rgba(255, 255, 255, 1)",
    "paper-elevation-3": "rgba(255, 255, 255, 1)",
    "paper-elevation-4": "rgba(255, 255, 255, 1)",
    "paper-elevation-5": "rgba(255, 255, 255, 1)",
    "paper-elevation-6": "rgba(255, 255, 255, 1)",
    "paper-elevation-7": "rgba(255, 255, 255, 1)",
    "paper-elevation-8": "rgba(255, 255, 255, 1)",
    "paper-elevation-9": "rgba(255, 255, 255, 1)",
    "paper-elevation-10": "rgba(255, 255, 255, 1)",
    "paper-elevation-11": "rgba(255, 255, 255, 1)",
    "paper-elevation-12": "rgba(255, 255, 255, 1)",
    "paper-elevation-13": "rgba(255, 255, 255, 1)",
    "paper-elevation-14": "rgba(255, 255, 255, 1)",
    "paper-elevation-15": "rgba(255, 255, 255, 1)",
    "paper-elevation-16": "rgba(255, 255, 255, 1)",
    "paper-elevation-17": "rgba(255, 255, 255, 1)",
    "paper-elevation-18": "rgba(255, 255, 255, 1)",
    "paper-elevation-19": "rgba(255, 255, 255, 1)",
    "paper-elevation-20": "rgba(255, 255, 255, 1)",
    "paper-elevation-21": "rgba(255, 255, 255, 1)",
    "paper-elevation-22": "rgba(255, 255, 255, 1)",
    "paper-elevation-23": "rgba(255, 255, 255, 1)",
    "paper-elevation-24": "rgba(255, 255, 255, 1)",
  },
  _inherit: {
    white: {
      focusvisible: "rgba(255, 255, 255, 0.3)",
    },
    textprimary: {
      focusvisible: "rgba(0, 0, 0, 0.3)",
    },
  },
  alert: {
    errorcontent: "rgba(84, 19, 19, 1)",
    errorfill: "rgba(251, 234, 234, 1)",
    warningcontent: "rgba(95, 43, 1, 1)",
    warningfill: "rgba(253, 240, 230, 1)",
    infocontent: "rgba(1, 54, 84, 1)",
    infofill: "rgba(230, 243, 250, 1)",
    successcontent: "rgba(18, 50, 20, 1)",
    successfill: "rgba(234, 242, 234, 1)",
  },
  appbar: {
    defaultfill: "rgba(245, 245, 245, 1)",
  },
  avatar: {
    fill: "rgba(189, 189, 189, 1)",
  },
  backdrop: {
    fill: "rgba(0, 0, 0, 0.5)",
  },
  breadcrumbs: {
    collapsefill: "rgba(245, 245, 245, 1)",
  },
  chip: {
    defaultclosefill: "rgba(0, 0, 0, 1)",
    defaulthoverfill: "rgba(0, 0, 0, 0.12)",
    defaultfocusfill: "rgba(255, 255, 255, 0.2)",
    defaultenabledborder: "rgba(97, 97, 97, 1)",
  },
  input: {
    standard: {
      hoverborder: "rgba(0, 0, 0, 1)",
    },
    filled: {
      hoverfill: "rgba(0, 0, 0, 0.09)",
    },
    outlined: {
      hoverborder: "rgba(0, 0, 0, 1)",
    },
  },
  rating: {
    enabledborder: "rgba(0, 0, 0, 0.23)",
  },
  colors: {
    grey: {
      a700: "rgba(48, 48, 48, 1)",
    },
    indigo: {
      a700: "rgba(48, 79, 254, 1)",
    },
    deeppurple: {
      a700: "rgba(98, 0, 234, 1)",
    },
    amber: {
      a700: "rgba(255, 171, 0, 1)",
    },
    orange: {
      a700: "rgba(255, 109, 0, 1)",
    },
    pink: {
      a700: "rgba(197, 17, 98, 1)",
    },
    deeporange: {
      a700: "rgba(221, 44, 0, 1)",
    },
    green: {
      a700: "rgba(0, 200, 83, 1)",
    },
    red: {
      a700: "rgba(213, 0, 0, 1)",
    },
    lightgreen: {
      a700: "rgba(100, 221, 23, 1)",
    },
    purple: {
      a700: "rgba(170, 0, 255, 1)",
    },
    lime: {
      a700: "rgba(174, 234, 0, 1)",
    },
    lightblue: {
      a700: "rgba(0, 145, 234, 1)",
    },
    yellow: {
      a700: "rgba(255, 214, 0, 1)",
    },
    cyan: {
      a700: "rgba(0, 184, 212, 1)",
    },
    teal: {
      a700: "rgba(0, 191, 165, 1)",
    },
    blue: {
      a700: "rgba(41, 98, 255, 1)",
    },
    bluegrey: {
      900: "rgba(38, 50, 56, 1)",
    },
  },
  switch: {
    knobfill: "rgba(250, 250, 250, 1)",
    slidefill: "rgba(0, 0, 0, 1)",
  },
};

export default palette;
