import { createTheme, Shadows } from "@mui/material/styles";
import effects from "./effects";
import palette from "./palette";
import typography from "./typography";

export const theme = createTheme({
  palette: palette,
  typography: typography,
  shadows: effects.elevation as Shadows,
});
