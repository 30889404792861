import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { firebaseAuth } from "@/services/auth";

const AuthContext = createContext<any>({});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<any>(null);
  const [userVerified, setUserVerified] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUserVerified(true);
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        });
        //setCustomClaims(user.uid, user.email);
      } else if (user && !user.emailVerified) {
        setUserVerified(false);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signup = async (
    email: string,
    password: string,
    displayName: string
  ) => {
    const newUser = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    await Promise.all([
      updateProfile(newUser.user, {
        displayName: displayName,
      }),
      //updateUserData(newUser.user.uid, newUser.user.email, displayName),
      //sendCustomEmailVerification(newUser.user.email),
      //setCustomClaims(newUser.user.uid, newUser.user.email),
    ]);
    return new Promise((resolve) => resolve(newUser));
  };

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
  };

  const signout = async () => {
    setUser(null);
    await signOut(firebaseAuth);
  };

  return (
    <AuthContext.Provider
      value={{ user, login, signup, signout, userVerified }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
